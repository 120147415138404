.App {
  font-size: calc(10px + 2vmin);
  color: black;
  overflow: hidden;
}

.backgroundmobile {
  background: #98dcc5;
  min-height: 100vh;
}

.backgrounddesktop {
  min-height: 100vh;
  background: linear-gradient(90deg, #98dcc5 50%, #2759fa 50%);
}

.backgrounddesktop h1 {
  padding-top: 1em;
  color: black;
  font-size: 400%;
}

.backgrounddesktop img {
  padding-top: 20px;
  height: 70%;
}
/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
